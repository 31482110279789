<!--
 * @LastEditTime: 2022-08-05 15:18:06
 * @Description: 各类中转页面集中处理 
 * @FilePath: /dataview-next/src/views/transit_page.vue
 * @Date: 2022-01-07 10:12:55
 * @Author: lisushuang
 * @LastEditors: lisushuang
-->

<template>
  <el-empty description="跳转中，请稍后..."></el-empty>
</template>

<script>
import { Empty } from 'element-ui'

export default {
  name:"transit_page",
  components:{'el-empty':Empty},
  mounted(){
    let url = this.$route.query.url
    if(url){
      this.$router.replace({path:url})
    }else{
      this.$router.go(-1)
    }
  }
}
</script>
